@import url('https://fonts.googleapis.com/css2?family=Gugi&display=swap');


:root {
  --text-color : #eeeaea;
  --nonogram-bg : #ecab1d;
  --border-color : #363636;
  --special-color : #4c008a;
  --text-color-dark : #111111;
  --bg-color-main: #d2dee6;
  --bg-color-side: #091052;
  --bg-color-bottom: #095246;
  --button-color: #4c008a;
  --button-color-hover: #823abd;
  --highlighted-color : #ecab1d;
  --link-color: #682a01;
  --link-color-hover: #aa4501;
}

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
 }

 index, html {
   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
   color: var(--text-color);
   font-size: 16px;
 }

 index, html div {
   overflow: hidden;
 }

textarea {
  resize: none;
  width: 100%;
  height:100px;
  border-radius: 6px;
  padding: 10px;
}

button {
  border: 0;
  font-size: 1rem;
  border-radius: 6px;
  padding: 6px;
  color: var(--text-color);
  margin: auto;
  background-color: var(--button-color);
}


button:hover {
  background-color: var(--button-color-hover);
  cursor: pointer;
}

/* layout components  */
 .mainLayout {
  display: grid;
  grid-template-columns: 2fr 1fr;
 }

 #mainDisplay {
  background-color: var(--bg-color-main);
  grid-row: 1;
  height: 70vh;
  padding-top: 10px;
 }

 #bottomPanel {
  background-color: var(--bg-color-bottom);
  grid-row: 2;
  height: 30vh;
 }

 #sidePanel {
  background-color: var(--bg-color-side);;
  grid-row: 1 / span 2;
  height: 100vh;
  padding-top: 10px;
 }

 .gap10 {
   height: 10px;
 }


 /* main display */

 #mainDisplay {
   display: flex;
   flex-direction: column;
   justify-content: center;
 }

 #mainDisplay h1 {
  color: var(--text-color-dark);
  font-family: 'Gugi', cursive;
 }
 
 
 #mainDisplay h3,
 #mainDisplay p,
 #mainDisplay li {
   color: var(--text-color-dark);
 }

 #mainDisplay p {
   font-size: .8rem;
 }

 .mainDisplayTextContainer {
   margin: 1rem;
 }

 .openRulesLink {
   color: var(--link-color);
 }

 .openRulesLink:hover {
  color: var(--link-color-hover);
  cursor: pointer;
}

 .letterGrid {
   display: grid;
   width: 9rem;
   grid-gap: 0;
   grid-template-columns: repeat(3, 1fr);
   margin: auto;
 }

 .letterItem {
   font-size: 2rem;
   background-color: var(--nonogram-bg);
   border : 1px var(--border-color) solid;
   margin: 1px;
   height: 3rem;
   text-align: center;
 }

 .specialLetter {
   background-color: var(--special-color);
 }

 .nonogramContainer {
   margin: auto;
 }

 .centerText {
   text-align: center;
 }

 /* bottom panel  */
 #bottomPanel {
   padding: 10px;
 }

 /* side panel  */
 #sidePanel  .resultsContainer{
   padding: 10px;
   height: 70vh;
   background-color: var(--bg-color-side);
   overflow: auto;
   margin : 10px;
 }

 #sidePanel .scoreButtonContainer {
  width: 100%; 
  display: flex;
 }

 .specialHighlight {
   color: var(--highlighted-color);
  font-size: larger;
 }

  /* modal  */

  .modalBg {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: rgba(17, 17, 17,0.8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .modalBg .modal {
    display: flex;
    margin: auto;
    border-radius: 10px;
    flex-direction: column;
    justify-content: space-around;
    padding: 2rem;
    width: 40%;
    height: 30%;
    background-color: var(--text-color);
  }

  .modalBg .modal p {
    color: var(--text-color-dark);
  }

  .modalBg .modal.rulesModal {
    color: var(--text-color-dark);
    width: 80%;
    height: 80%;
  }

  .modalBg .modal .buttonBar {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .modalBg .modal .buttonBar button {
    flex: 1;
    margin-right: 10px;
  }

  .modalBg .modal ol {
    margin : 1rem;
  }


/* loading spinner  */

.loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255,0.8) ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--text-color-dark);
}

.lds-dual-ring {
    display: inline-block;
    width: 100px;
    height: 100px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid var(--bg-color-bottom);
    border-color: var(--bg-color-bottom)transparent var(--bg-color-bottom) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media(max-width:550px) {
  index, html {
    font-size: 12px;
    background-color: var(--bg-color-side);
  }

  .mainLayout {
    display: grid;
    grid-template-columns: 1fr;
   }

   #mainDisplay {
    grid-row: 1;
    height: auto;
    padding-bottom: 10px;
   }
  
   #bottomPanel {
    grid-row: 2;
    height: auto;
   }
  
   #sidePanel {
    grid-row: 3;
    height: auto;
    padding-bottom: 10px;
   }

  #sidePanel .resultsContainer {
    height: auto;
  }

  .modalBg .modal.rulesModal .rulesArea {
    overflow-y: scroll;
  }
  
}